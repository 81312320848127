<template>
  <div>
    <component :is="resolveLayout">
      <transition name="fade"
          mode="out-in">
        <router-view></router-view>
      </transition>
    </component>

    <v-snackbar v-model='snackbar' timeout='2500' class="mb-5">
      {{ snackbarMessage }}
    </v-snackbar>
    <v-dialog max-width="400" persistent v-model="updatePwaDialogState" width="400">
      <v-card>
        <v-card-title>
          <v-icon color="success" left>fa-exclamation-circle</v-icon>
          <span>Update Available</span>
        </v-card-title>
        <v-card-text>Click OK to update application.</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="updatePwaCache()" color="info">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import EventBus, { ACTIONS } from './EventBus/index'

export default {
  components: {
    LayoutBlank,
    LayoutContent
  },
  data: () => ({
    snackbar: false,
    snackbarMessage: '',
    loader: false,
    vLoader: false,
    updatePwaDialogState: false,
    registration: null,
    refreshing: false
  }),
  setup () {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout
    }
  },

  methods: {
    updatePwaCache () {
      this.updatePwaDialogState = false
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    showUpdate (e) {
      this.registration = e.detail
      this.updatePwaDialogState = true
    }
  },

  created () {
    document.addEventListener(
      'serviceWorkerUpdateEvent', this.showUpdate, { once: true }
    )

    navigator.serviceWorker.addEventListener(
      'controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      }
    )
  },

  mounted () {
    EventBus.$on(ACTIONS.SNACKBAR, (message) => {
      this.snackbarMessage = message
      this.snackbar = true
    })
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
