<template>
<div>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ user.name }}
          </span>
          <small class="text--disabled text-capitalize">{{ user.email }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item link @click="gotoMyProfile()">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Email -->
      <v-list-item link @click="goToDisposisi()">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Disposisi Saya</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-badge
            inline
            color="warning"
            :content="disposisiCount"
          >
          </v-badge>
        </v-list-item-action>
      </v-list-item>

      <!-- Chat -->
      <!-- <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCheckDecagram }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Perlu Persetujuan</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge
            inline
            color="error"
            content="2"
          >
          </v-badge>
        </v-list-item-action>
      </v-list-item> -->

      <v-divider class="my-2"></v-divider>
      <!-- Logout -->
      <v-list-item link @click="logout()">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
  <confirmation-dialog ref="veeConfirmRef"></confirmation-dialog>
  <overlay-loading ref="vLoader"></overlay-loading>
</div>
</template>

<script>
import { Component, Ref } from 'vue-property-decorator'
import Vue from 'vue'
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiCheckDecagram
} from '@mdi/js'
import store from '@/store'
import ConfirmationDialog from '../components/ConfirmationDialog.vue'
import OverlayLoading from '../components/OverlayLoading.vue'

@Component({
  components: {
    ConfirmationDialog,
    OverlayLoading
  }
})
export default class AppBarUserMenu extends Vue {
  @Ref veeConfirmRef
  @Ref vLoader

  icons = {
    mdiAccountOutline,
    mdiEmailOutline,
    mdiCheckboxMarkedOutline,
    mdiChatOutline,
    mdiCogOutline,
    mdiCurrencyUsd,
    mdiHelpCircleOutline,
    mdiLogoutVariant,
    mdiCheckDecagram
  }

  get user () {
    return this.$store.state.auth.user
  }

  get disposisiCount () {
    const cnt = this.$store.state.utilities.myDisposisi
    let a = ''
    if (cnt > 0) a = cnt
    return a
  }

  logout () {
    this.$refs.veeConfirmRef.open('Konfirmasi', 'Anda akan keluar dari aplikasi SIPADU, Lanjutkan ?', { color: 'primary' })
      .then(confirm => {
        if (confirm) {
          this.$refs.vLoader.open('Logging out ...')
          setTimeout(() => {
            store.dispatch('auth/logout').then(() => {
              this.$refs.vLoader.close()
              this.$router.push('/login')
            })
          }, 2000)
        }
      })
  }

  goToDisposisi () {
    this.$router.push({ name: 'disposisi' })
  }

  gotoMyProfile () {
    this.$router.push({ name: 'myProfile' })
  }

  imageGender (gender) {
    const images = require.context('@/assets/images/avatars/', false, /\.png$/)
    return gender === 'L' ? images('./1.png') : gender === 'P' ? images('./2.png') : images('./3.png')
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
