<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/rsud.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            SIPADU
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiViewDashboard"
      ></nav-menu-link>

      <nav-menu-link
        title="Disposisi"
        :to="{ name: 'disposisi' }"
        :icon="icons.mdiEmailNewsletter"
        :badge="'new'"
        :badgeColor="'warning'"
        :badgeValues="countMyDisposisi.toString()"
      >
      </nav-menu-link>

      <nav-menu-link
        title="Persetujuan"
        :to="{ name: 'approvalList' }"
        :icon="icons.mdiCheckDecagram"
        :badge="'new'"
        :badgeColor="'warning'"
        :badgeValues="countMyApproval.toString()"
      >
      </nav-menu-link>

      <nav-menu-link
        title="Surat Masuk"
        :to="{ name: 'inbox'}"
        :icon="icons.mdiEmailReceive"
      ></nav-menu-link>

      <nav-menu-link
        title="Surat Keluar"
        :to="{ name: 'outbox'}"
        :icon="icons.mdiEmailSend"
      ></nav-menu-link>

      <nav-menu-link
        v-if="getGroupUser === 5 || getGroupUser === 1 || getGroupUser === 6 || getGroupUser === 3"
        title="Dokumen Keuangan"
        :to="{ name: 'suratKeuangan'}"
        :icon="icons.mdiEmailLock"
      ></nav-menu-link>

      <nav-menu-link
        v-if="getGroupUser === 1 || getGroupUser === 2 || getGroupUser === 6 || getGroupUser === 3"
        title="Arsip Vital"
        :to="{ name: 'suratVital'}"
        :icon="icons.mdiEmailAlert"
      ></nav-menu-link>

      <nav-menu-link
        title="Permohonan Cuti"
        :to="{ name: 'listCuti'}"
        :icon="icons.mdiFileSign"
      ></nav-menu-link>

      <nav-menu-link
        title="Dokumen Lainnya"
        :to="{ name: 'otherDocs'}"
        :icon="icons.mdiFileMultiple"
      ></nav-menu-link>

      <nav-menu-group
        v-if="getGroupUser === 1 || getGroupUser === 2 || getGroupUser === 3"
        title="Persuratan"
        :icon="icons.mdiGmail"
      >
        <nav-menu-link
          title="Sifat Surat"
          :to="{ name: 'SifatSurat' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Klasifikasi Surat"
          :to="{ name: 'KlasifikasiSurat' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Rekanan"
          :to="{ name: 'Rekanan' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        v-if="getGroupUser === 3 || getGroupUser === 1"
        title="Arsiparis"
        :icon="icons.mdiCardAccountDetails"
      >
        <nav-menu-link
          title="Rak Asrip"
          :to="{ name: 'RakArsip' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Box Arsip"
          :to="{ name: 'BoxArsip' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Map Arsip"
          :to="{ name: 'MapArsip' }"
        ></nav-menu-link>
      </nav-menu-group>

      <!-- <nav-menu-group
        title="e-Office"
        :icon="icons.mdiOfficeBuilding"
      >
        <nav-menu-link
          title="Permohonan Cuti"
          :to="{ name: 'user-list' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Tukar Shift"
          :to="{ name: 'user-group' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Izin Keluar"
          :to="{ name: 'user-group' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Izin Tidak Masuk"
          :to="{ name: 'user-group' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-link
        title="Arsip Surat"
        :to="{ name: 'archive'}"
        :icon="icons.mdiArchive"
      ></nav-menu-link> -->

      <nav-menu-group
        v-if="getGroupUser === 2 || getGroupUser === 1"
        title="Organisasi"
        :icon="icons.mdiSourceBranch"
      >
        <nav-menu-link
          title="Jabatan"
          :to="{ name: 'jabatan' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Pegawai"
          :to="{ name: 'employee' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Cuti Pegawai"
          :to="{ name: 'leaveMasterEmployee' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        v-if="getGroupUser === 1"
        title="Admin"
        :icon="icons.mdiShieldAccount"
      >
        <nav-menu-link
          title="User"
          :to="{ name: 'user-list' }"
        ></nav-menu-link>
        <!-- <nav-menu-link
          title="User Group"
          :to="{ name: 'user-group' }"
        ></nav-menu-link> -->
      </nav-menu-group>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiArchive,
  mdiSourceBranch,
  mdiCog,
  mdiEmailOpen,
  mdiEmailPlus,
  mdiGmail,
  mdiEmailReceive,
  mdiEmailSend,
  mdiEmailFast,
  mdiShieldAccount,
  mdiFileChart,
  mdiEmail,
  mdiOfficeBuilding,
  mdiViewDashboard,
  mdiEmailAlert,
  mdiEmailLock,
  mdiCardAccountDetails,
  mdiEmailNewsletter,
  mdiCheckDecagram,
  mdiFileSign,
  mdiFileMultiple
} from '@mdi/js'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuGroup,
    NavMenuLink
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    countMyDisposisi () {
      const cnt = this.$store.state.utilities.myDisposisi
      let a = ''
      if (cnt > 0) a = cnt
      return a
    },
    countMyApproval () {
      const cnt = this.$store.state.utilities.myApproval
      let a = ''
      if (cnt > 0) a = cnt
      return a
    },
    getGroupUser () {
      return this.$store.state.auth.user.group_id
    }
  },
  setup () {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiArchive,
        mdiSourceBranch,
        mdiCog,
        mdiEmailOpen,
        mdiEmailPlus,
        mdiGmail,
        mdiEmailReceive,
        mdiEmailSend,
        mdiEmailFast,
        mdiShieldAccount,
        mdiFileChart,
        mdiEmail,
        mdiOfficeBuilding,
        mdiViewDashboard,
        mdiEmailAlert,
        mdiEmailLock,
        mdiCardAccountDetails,
        mdiEmailNewsletter,
        mdiCheckDecagram,
        mdiFileSign,
        mdiFileMultiple
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
