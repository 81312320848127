import axios from 'axios'

export default {
  namespaced: true,
  state: {
    myDisposisi: 0,
    myApproval: 0
  },
  getters: {
    countMyDisposisiGetter (state) {
      return state.myDisposisi
    },
    countMyApprovalGetter (state) {
      return state.myApproval
    }
  },
  mutations: {
    SET_NUMBER_DISPOSISI (state, value) {
      state.myDisposisi = value
    },
    SET_NUMBER_APPROVAL (state, value) {
      state.myApproval = value
    }
  },
  actions: {
    countMyDisposisi ({ commit }) {
      axios.get('/api/surat/disposisi/me/count').then(({ responseData }) => {
        commit('SET_NUMBER_DISPOSISI', responseData)
      }).catch((e) => {
        alert('error', e)
      })
    },
    countMyApproval ({ commit }) {
      axios.get('/api/myApproval/count').then(({ responseData }) => {
        commit('SET_NUMBER_APPROVAL', responseData)
      }).catch((e) => {
        alert('error', e)
      })
    }
  }
}
