import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/DashboardView.vue'),
    meta: {
      title: 'Dashboard',
      middleware: 'auth',
      breadCrumb: [
        {
          text: 'Dashboard'
        }
      ]
    }
  },
  {
    path: '/inbox/:type/create-new',
    name: 'createNewMail',
    component: () => import('@/views/pages/NewMail.vue'),
    meta: {
      title: 'Buat Surat Masuk',
      breadCrumb (route) {
        const type = route.params.type
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Surat Masuk',
            to: {
              name: 'inbox',
              params: {
                type: type
              }
            }
          },
          {
            text: 'Baru'
          }
        ]
        return result
      }
    }
  },
  {
    path: '/inbox/:type/:id/:status',
    name: 'formInboxMail',
    component: () => import('@/views/pages/NewMail.vue'),
    meta: {
      title: 'Form Surat Masuk',
      breadCrumb (route) {
        const type = route.params.type
        const id = route.params.id
        const status = route.params.status
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Surat Masuk',
            to: {
              name: 'inbox',
              params: {
                type: type,
                id: id,
                status: status
              }
            }
          },
          {
            text: status
          }
        ]
        return result
      }
    }
  },
  {
    path: '/outbox/:type/:id/:status',
    name: 'formOutboxMail',
    component: () => import('@/views/pages/NewMail.vue'),
    meta: {
      title: 'Form Surat Keluar',
      breadCrumb (route) {
        const type = route.params.type
        const id = route.params.id
        const status = route.params.status
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Surat Keluar',
            to: {
              name: 'outbox',
              params: {
                type: type,
                id: id,
                status: status
              }
            }
          },
          {
            text: status
          }
        ]
        return result
      }
    }
  },
  {
    path: '/suratVital/:type/:id/:status',
    name: 'formVitalMail',
    component: () => import('@/views/pages/NewMail.vue'),
    meta: {
      title: 'Form Arsip Vital',
      breadCrumb (route) {
        const type = route.params.type
        const id = route.params.id
        const status = route.params.status
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Arsip Vital',
            to: {
              name: 'suratVital',
              params: {
                type: type,
                id: id,
                status: status
              }
            }
          },
          {
            text: status
          }
        ]
        return result
      }
    }
  },
  {
    path: '/suratKeuangan/:type/:id/:status',
    name: 'formKeuanganMail',
    component: () => import('@/views/pages/NewMail.vue'),
    meta: {
      title: 'Form Dokumen Keuangan',
      breadCrumb (route) {
        const type = route.params.type
        const id = route.params.id
        const status = route.params.status
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Dokumen Keuangan',
            to: {
              name: 'suratKeuangan',
              params: {
                type: type,
                id: id,
                status: status
              }
            }
          },
          {
            text: status
          }
        ]
        return result
      }
    }
  },
  {
    path: '/outbox/:type/create-new',
    name: 'createOutboxMail',
    component: () => import('@/views/pages/NewMail.vue'),
    meta: {
      title: 'Buat Surat Keluar',
      breadCrumb (route) {
        const type = route.params.type
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Surat Keluar',
            to: {
              name: 'outbox',
              params: {
                type: type
              }
            }
          },
          {
            text: 'Baru'
          }
        ]
        return result
      }
    }
  },
  {
    path: '/suratVital/:type/create-new',
    name: 'createVitalMail',
    component: () => import('@/views/pages/NewMail.vue'),
    meta: {
      title: 'Buat Arsip Vital',
      breadCrumb (route) {
        const type = route.params.type
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Arsip Vital',
            to: {
              name: 'suratVital',
              params: {
                type: type
              }
            }
          },
          {
            text: 'Baru'
          }
        ]
        return result
      }
    }
  },
  {
    path: '/suratKeuangan/:type/create-new',
    name: 'createKeuanganMail',
    component: () => import('@/views/pages/NewMail.vue'),
    meta: {
      title: 'Buat Dokumen Keuangan',
      breadCrumb (route) {
        const type = route.params.type
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Dokumen Keuangan',
            to: {
              name: 'suratKeuangan',
              params: {
                type: type
              }
            }
          },
          {
            text: 'Baru'
          }
        ]
        return result
      }
    }
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import('@/views/pages/InboxView.vue'),
    meta: {
      title: 'Surat Masuk',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Surat Masuk'
        }
      ]
    }
  },
  {
    path: '/outbox',
    name: 'outbox',
    component: () => import('@/views/pages/OutboxView.vue'),
    meta: {
      title: 'Surat Keluar',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Surat Keluar'
        }
      ]
    }
  },
  {
    path: '/suratVital',
    name: 'suratVital',
    component: () => import('@/views/pages/SuratVitalView.vue'),
    meta: {
      title: 'Arsip Vital',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Arsip Vital'
        }
      ]
    }
  },
  {
    path: '/suratKeuangan',
    name: 'suratKeuangan',
    component: () => import('@/views/pages/SuratKeuanganView.vue'),
    meta: {
      title: 'Dokumen Keuangan',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Dokumen Keuangan'
        }
      ]
    }
  },
  {
    path: '/organization/jabatan',
    name: 'jabatan',
    component: () => import('@/views/pages/OrganizationView.vue'),
    meta: {
      title: 'List Jabatan',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'List Jabatan'
        }
      ]
    }
  },
  {
    path: '/organization/employee',
    name: 'employee',
    component: () => import('@/views/pages/EmployeeView.vue'),
    meta: {
      title: 'List Pegawai',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'List Pegawai'
        }
      ]
    }
  },
  {
    path: '/archive',
    name: 'archive',
    component: () => import('@/views/pages/ArchiveMail.vue'),
    meta: {
      title: 'Arsip Surat'
    }
  },
  {
    path: '/users',
    name: 'user-list',
    component: () => import('@/views/pages/UserList.vue'),
    meta: {
      title: 'List Users',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'User List'
        }
      ]
    }
  },
  {
    path: '/arsiparis/rak-arsip',
    name: 'RakArsip',
    component: () => import('@/views/pages/RakArsip.vue'),
    meta: {
      title: 'Rak Arsip',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Arsiparis'
        },
        {
          text: 'Rak Arsip'
        }
      ]
    }
  },
  {
    path: '/organization/leave/employee',
    name: 'leaveMasterEmployee',
    component: () => import('@/views/pages/CutiPegawai.vue'),
    meta: {
      title: 'Master Cuti Pegawai',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Organisasi'
        },
        {
          text: 'Master Cuti Pegawai'
        }
      ]
    }
  },
  {
    path: '/leave',
    name: 'listCuti',
    component: () => import('@/views/pages/PengajuanCuti.vue'),
    meta: {
      title: 'Daftar Permhonan Cuti',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Permohonan Cuti'
        }
      ]
    }
  },
  {
    path: '/leave/create-new',
    name: 'createNewLeave',
    component: () => import('@/views/pages/FormCuti.vue'),
    meta: {
      title: 'Form Permohonan Cuti',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Permohonan Cuti',
          to: { name: 'listCuti' }
        },
        {
          text: 'Baru'
        }
      ]
    }
  },
  {
    path: '/leave/:id/:status',
    name: 'editFormCuti',
    component: () => import('@/views/pages/FormCuti.vue'),
    meta: {
      title: 'Form Permohonan Cuti',
      breadCrumb (route) {
        const status = route.params.status
        const result = [
          {
            text: 'Dashboard',
            to: { name: 'dashboard' }
          },
          {
            text: 'Permohonan Cuti',
            to: { name: 'listCuti' }
          },
          {
            text: status
          }
        ]
        return result
      }
    }
  },
  {
    path: '/approval',
    name: 'approvalList',
    component: () => import('@/views/pages/ApprovalList.vue'),
    meta: {
      title: 'Daftar Persetujuan',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Daftar Persetujuan'
        }
      ]
    }
  },
  {
    path: '/approval/leave/:id/:status',
    name: 'leaveApproval',
    component: () => import('@/views/pages/FormCuti.vue'),
    meta: {
      title: 'Permohonan Cuti',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Daftar Persetujuan',
          to: { name: 'approvalList' }
        },
        {
          text: 'Permohonan Cuti'
        }
      ]
    }
  },
  {
    path: '/arsiparis/box-arsip',
    name: 'BoxArsip',
    component: () => import('@/views/pages/BoxArsip.vue'),
    meta: {
      title: 'Box Arsip',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Arsiparis'
        },
        {
          text: 'Box Arsip'
        }
      ]
    }
  },
  {
    path: '/arsiparis/map-arsip',
    name: 'MapArsip',
    component: () => import('@/views/pages/MapArsip.vue'),
    meta: {
      title: 'Map Arsip',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Arsiparis'
        },
        {
          text: 'Map Arsip'
        }
      ]
    }
  },
  {
    path: '/persuratan/sifat',
    name: 'SifatSurat',
    component: () => import('@/views/pages/SifatSurat.vue'),
    meta: {
      title: 'Sifat Surat',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Persuratan'
        },
        {
          text: 'Sifat Surat'
        }
      ]
    }
  },
  {
    path: '/persuratan/klasifikasi',
    name: 'KlasifikasiSurat',
    component: () => import('@/views/pages/KlasifikasiSurat.vue'),
    meta: {
      title: 'Klasifikasi Surat',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Persuratan'
        },
        {
          text: 'Klasifikasi Surat'
        }
      ]
    }
  },
  {
    path: '/persuratan/rekanan',
    name: 'Rekanan',
    component: () => import('@/views/pages/Partner.vue'),
    meta: {
      title: 'Rekanan',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Persuratan'
        },
        {
          text: 'Rekanan'
        }
      ]
    }
  },
  {
    path: '/disposisi',
    name: 'disposisi',
    component: () => import('@/views/pages/MyDisposisiList.vue'),
    meta: {
      title: 'Disposisi Surat',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Disposisi Surat'
        }
      ]
    }
  },
  {
    path: '/disposisi/:id/view',
    name: 'detailDisposisi',
    component: () => import('@/views/pages/DetailDisposisiSurat.vue'),
    meta: {
      title: 'Lihat Disposisi',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Disposisi Surat',
          to: { name: 'disposisi' }
        },
        {
          text: 'View'
        }
      ]
    }
  },
  {
    path: '/myProfile',
    name: 'myProfile',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      title: 'Profile Saya',
      breadCrumb: [
        {
          text: 'Profile Saya'
        }
      ]
    }
  },
  {
    path: '/user-group',
    name: 'user-group',
    component: () => import('@/views/pages/UserGroup.vue'),
    meta: {
      title: 'Group User'
    }
  },
  {
    path: '/docs',
    name: 'otherDocs',
    component: () => import('@/views/pages/OtherDocsList.vue'),
    meta: {
      title: 'Dokumen Lainnya',
      breadCrumb: [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' }
        },
        {
          text: 'Dokumen Lainnya'
        }
      ]
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/pages/ReportsView.vue'),
    meta: {
      title: 'Laporan'
    }
  },
  {
    path: '/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      title: 'Login',
      layout: 'blank',
      middleware: 'guest'
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/document/verification/:token',
    name: 'verificationDocument',
    component: () => import('@/views/pages/VerifikasiDokumen.vue'),
    meta: {
      layout: 'blank',
      title: 'Verifikasi Dokumen',
      middleware: 'guest'
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.middleware === 'guest') {
    if (store.state.auth.authenticated) {
      if (to.name === 'verificationDocument') {
        next()
      } else {
        next({ name: 'dashboard' })
      }
    }
    next()
  } else {
    if (store.state.auth.authenticated) {
      next()
    } else {
      next({ name: 'login' })
    }
  }
})

export default router
