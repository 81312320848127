const required = (value) => (value !== undefined && value !== null && value !== '') || 'This field is required'
const maxLength = (value, maxLength) => (value !== undefined && value !== null && value !== '') ? (value && value.length <= maxLength) || `Must be less than ${maxLength} characters` : true
const minLength = (value, minLength) => (value !== undefined && value !== null && value !== '') ? (value && value.length >= minLength) || `Must not be less than ${minLength} characters` : true
const email = (value) => (value !== undefined && value !== null && value !== '') ? /.+@.+\..+/.test(value) || 'Must be a valid email address' : true
const noConsecutiveSpaces = (value) => (value !== undefined && value !== null && value !== '') ? !/ {2}/gmi.test(value) || 'No consecutive spaces' : true
const numbersOnly = (value) => (value !== undefined && value !== null && value !== '') ? /^[0-9]*$/.test(value) || 'Must be numbers only' : true
const uppercaseLettersOnly = (value) => (value !== undefined && value !== null && value !== '') ? /^[A-Z ]+$/.test(value) || 'Must be uppercase letters only' : true
const date = (value) => (value !== undefined && value !== null && value !== '') ? /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(value) || 'Must be a valid date format (dd-mm-yyyy)' : true
const greaterEqual = (value, min) => (value !== undefined && value !== null && value !== '') ? (parseInt(value) >= min) || `Must be greater than ${min}` : true
const lesserEqual = (value, max) => (value !== undefined && value !== null && value !== '') ? (parseInt(value) <= max) || `Must be lesser than ${max}` : true
const nomorPolisi = (value) => (value !== undefined && value !== null && value !== '') ? /^[a-z]+-\d+-[a-z]+$/i.test(value) || 'Format Nomor Polisi Tidak Sesuai' : true
const alphaNumericOnly = (value) => (value !== undefined && value !== null && value !== '') ? /^[a-zA-Z0-9]*$/.test(value) || 'Must be alphanumeric characters only' : true
const password = (value) => (value !== undefined && value !== null && value !== '') ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value) || 'Must contain at least one uppercase letter, one lowercase letter, one number and one special character' : true
const passwordNew = (value) => (value !== undefined && value !== null && value !== '') ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/.test(value) || 'Must contain at least one uppercase letter, one lowercase letter and one number' : true
const decimalInput = (value) => (value !== undefined && value !== null && value !== '') ? /^[0-9]*\.?[0-9]*$/.test(value) || 'Invalid number format' : true
const maxValue = (value, maxLength) => (value !== undefined && value !== null && value !== 0) ? (value && value <= maxLength) || `Must be less than ${maxLength}` : true
const maxNominal = (value, maxNumber) => (value !== undefined && value !== null && value !== 0) ? (value && value < maxNumber) || 'Must be less than Rp. ' + (maxNumber - 1).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : true
const minNominal = (value, minNumber) => (value !== undefined && value !== null && value !== 0) ? (value && value > minNumber) || 'Must not be less than Rp. ' + (minNumber + 1).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : true
const nomorHp = (value) => (value !== undefined && value !== null && value !== '') ? value.substring(0, 2) === '08' || 'Format nomor handphone harus diawali dengan 08' : true
const tahunBerdiri = (value) => (value !== undefined && value !== null) ? value >= 1 || 'Badan usaha harus sudah berdiri minimal 1 tahun' : true
const tahunLahir = (value) => (value !== undefined && value !== null) ? value >= 17 || 'Nasabah / penanggung jawab harus sudah minimal 17 tahun' : true
const confirmPassword = (value1, value2) => (value1 !== undefined && value1 !== null && value2 !== undefined && value2 !== null) ? value1 === value2 || 'Confirmation password does not match' : true
export default {
  required,
  maxLength,
  minLength,
  email,
  noConsecutiveSpaces,
  numbersOnly,
  uppercaseLettersOnly,
  date,
  greaterEqual,
  lesserEqual,
  nomorPolisi,
  alphaNumericOnly,
  password,
  decimalInput,
  maxValue,
  passwordNew,
  minNominal,
  maxNominal,
  nomorHp,
  tahunBerdiri,
  tahunLahir,
  confirmPassword
}
