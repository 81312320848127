<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          {{ message }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
  }
})

export default class OverlayLoading extends Vue {
  dialog = false
  message = ''

  open (message) {
    this.dialog = true
    this.message = message
  }

  close () {
    this.dialog = false
  }
}
</script>
