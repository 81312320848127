import Vue from 'vue'

export const ACTIONS = {
  SNACKBAR: 'snackbar',
  OVERLAY_LOADER: 'overlayLoader'
}

const EventBus = new Vue()

export default EventBus
