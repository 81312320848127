export default {
  getFormattedDate (value) {
    let formatted = ''
    if (value && value !== 'null') {
      const [year, month, day] = value.split('-')
      formatted = `${day}-${month}-${year}`
    }
    return formatted
  },
  getFormattedNumber (value, minimumFractionDigits = 0, maximumFractionDigits = 0) {
    let formatted = ''
    if (value) {
      if (isNaN(Number(value))) {
        formatted = ''
      } else {
        formatted = Number(value).toLocaleString('en-US', { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits }) || ''
      }
    }
    return formatted
  },
  getUnformattedNumber (value) {
    let unformatted = ''
    if (value) {
      const number = Number(value.replace(/[^0-9.-]/g, ''))
      if (!isNaN(number)) {
        unformatted = String(number)
      }
    }
    return unformatted
  },
  getFormattedDateTime (value) {
    let formatted = ''
    if (value && value !== 'null') {
      const [date, time] = value.split(' ')
      const [year, month, day] = date.split('-')
      const [hh, mm, ss] = time.split(':')
      formatted = `${day}-${month}-${year} ${hh}:${mm}:${ss}`
    }
    return formatted
  },
  getRandomString (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },
  getGenderLabel (value) {
    const gender = {
      L: 'Laki-Laki',
      P: 'Perempuan'
    }
    return gender[value]
  }
}
