import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VuePageTitle from 'vue-page-title'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import AES from 'crypto-js/aes'
import { enc } from 'crypto-js'
import JsonExcel from 'vue-json-excel'
import VueSignaturePad from 'vue-signature-pad'

Vue.config.productionTip = false
Vue.prototype.$http = axios

const encryptId = (str) => {
  const ciphertext = AES.encrypt(str, process.env.VUE_APP_SECRET_KEY)
  return ciphertext.toString()
}

const decryptId = (decodedStr) => {
  return AES.decrypt(decodedStr, process.env.VUE_APP_SECRET_KEY).toString(enc.Utf8)
}

Vue.prototype.$encrypt = encryptId
Vue.prototype.$decrypt = decryptId

axios.interceptors.response.use((response) => {
  return response.data
}, (error) => {
  const originalRequest = error.config
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    store.dispatch('auth/logout')
    return router.push('/login')
  } else {
    if (error.response.data.errorMessage) {
      throw new Error(error.response.data.errorMessage)
    }
    return Promise.reject(error)
  }
})

axios.interceptors.request.use(config => {
  config.headers = {
    Authorization: 'Bearer ' + store.state.auth.token,
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
  config.baseURL = process.env.VUE_APP_API_BASE_URL
  return config
})

if (store.state.auth.authenticated) {
  const token = store.state.auth.token
  store.dispatch('auth/validate', token).then(() => {
    store.dispatch('utilities/countMyDisposisi')
    store.dispatch('utilities/countMyApproval')
  }).catch(() => {
    router.push('/login')
  })
}

Vue.use(VueSignaturePad)
Vue.use(require('vue-moment'))
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueSweetalert2, crypto)
Vue.use(VuePageTitle, {
  suffix: '- Sipadu',
  router
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
