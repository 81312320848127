import router from '../router'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    token: ''
  },
  getters: {
    authenticated (state) {
      return state.authenticated
    },
    user (state) {
      return state.user
    },
    token (state) {
      return state.token
    }
  },
  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
    SET_USER (state, value) {
      state.user = value
    },
    SET_TOKEN (state, value) {
      state.token = value
    }
  },
  actions: {
    login ({ commit }, userData) {
      commit('SET_USER', userData.user)
      commit('SET_AUTHENTICATED', true)
      commit('SET_TOKEN', userData.token)
      router.push({ name: 'dashboard' })
    },
    logout ({ commit }) {
      return new Promise((resolve) => {
        axios.get('/api/logout').then((r) => {
          commit('SET_USER', {})
          commit('SET_AUTHENTICATED', false)
          commit('SET_TOKEN', '')
          resolve()
        }).catch((e) => {
          alert('error', e)
        })
      })
    },
    validate ({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get('/api/user', { headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' } }).then((userData) => {
          commit('SET_USER', userData)
          commit('SET_AUTHENTICATED', true)
          commit('SET_TOKEN', token)
          resolve(userData)
        }).catch((err) => {
          commit('SET_USER', {})
          commit('SET_AUTHENTICATED', false)
          commit('SET_TOKEN', '')
          reject(err)
        })
      })
    }
  }
}
